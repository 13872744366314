import {useLoaderData} from '@remix-run/react';
import type {LinksFunction} from '@remix-run/server-runtime';

import blogStylesheet from '@/stylesheets/blog/blog.css?url';
import PageLayout from '@/components/shared/Page/PageLayout';
import {BlogSubNav} from '@/components/pages/blog/BlogSubNav/BlogSubNav';
import Typography from '@/components/base/elements/Typography/Typography';
import {useTranslations} from '@/components/shared/Page/I18N';
import BlogCard from '@/components/pages/blog/BlogCard/BlogCard';
import type {
  Article,
  NumberedPagesPageInfo,
  Topic,
} from '@/components/blog/types';
import {BLOG_PAGE_TYPE} from '@/components/blog/types';
import {BlogConversion} from '@/components/blog/BlogConversion/BlogConversion';
import {FakeCursorPagination} from '@/components/pages/blog/FakeCursorPagination/FakeCursorPagination';
import {baseUri} from '@/constants';
import {EN_BLOG_PATH} from '@/utils/blog/partnersHelpers';
import {partnersPath} from '@/hooks/navigation/utils';
import {useSiteData} from '@/components/shared/Page/SiteData';
import {PartnersFooter} from '@/pages/shopify.com/($locale)/partners/blog/components/PartnersFooter/PartnersFooter';
import {usePaginatedPageTitle} from '@/hooks/blog/usePaginatedPageTitle';
import {useBlogPageTitle} from '@/hooks/blog/useBlogPageTitle';

export const links: LinksFunction = () => {
  return [{rel: 'stylesheet', href: blogStylesheet}];
};

export {topicLoader as loader} from '@/utils/blog/topicLoader.server';
export {partnersHandle as handle} from '@/pages/shopify.com/($locale)/partners/blog/handle';

export default function BlogTopic() {
  const {t} = useTranslations();
  const {site} = useSiteData();

  const {articles, pagination, topicContent, url} =
    useLoaderData<{
      articles: Article[];
      pagination: NumberedPagesPageInfo;
      topicContent: Topic;
      url: string;
    }>() ?? {};

  const topicName = topicContent.title;
  const pageTitle = useBlogPageTitle(
    t('htmlHead.pageTitle', {topic: topicName}),
  );
  const pageTitleWithPageNumber = usePaginatedPageTitle(pageTitle);

  const currentURL = new URL(url, baseUri);
  const subTitle = topicContent.subTitle;

  const heroArticles = articles.slice(0, 3);
  const articlesRest = articles.slice(3);

  return (
    <PageLayout
      title={pageTitleWithPageNumber}
      metaDescription={t('htmlHead.metaDescription', {topic: topicName})}
      header={(props) => (
        <BlogSubNav
          {...props}
          enBlogPath={EN_BLOG_PATH}
          blogPageType={BLOG_PAGE_TYPE.PARTNERS}
        />
      )}
      footer={<PartnersFooter />}
      isPureTitle
    >
      <div className="pt-36">
        <div className="py-10 tablet:py-12 desktop:py-16 bg-[#F6F6F1]">
          <div className="container flex tablet:justify-center">
            <div className="max-w-[832px]">
              <Typography
                as="h1"
                className="text-3xl tablet:text-4xl desktop:text-5xl font-medium tablet:text-center mb-2"
              >
                {topicName}
              </Typography>
              {subTitle && (
                <Typography className="tablet:text-center text-sm">
                  {subTitle}
                </Typography>
              )}
            </div>
          </div>
        </div>
        <div className="container py-10 desktop:py-16">
          <div className="grid grid-cols-1 tablet:grid-cols-3 gap-4 desktop:gap-6 mb-8 tablet:mb-6 desktop:mb-12">
            {Boolean(heroArticles) &&
              heroArticles.map((article: Article, index: number) => (
                <BlogCard
                  className="border-t border-shade-30 pt-4"
                  key={`grid-3-${index}`}
                  article={article}
                  enBlogPath={EN_BLOG_PATH}
                  type="grid-3"
                />
              ))}
          </div>
          <div className="grid grid-cols-1 tablet:grid-cols-4 gap-4 desktop:gap-6 gap-y-6 desktop:gap-y-9">
            {Boolean(articlesRest) &&
              articlesRest.map((article: Article, index: number) => (
                <BlogCard
                  className="border-t border-shade-30"
                  key={`grid-4-${index}`}
                  article={article}
                  enBlogPath={EN_BLOG_PATH}
                />
              ))}
          </div>
          <FakeCursorPagination
            pagination={pagination}
            currentURL={currentURL}
          />
        </div>
        <BlogConversion
          heading={t('sellAnywhere.headingHtml')}
          buttonHref={partnersPath(site)}
          buttonText={t('sellAnywhere.buttonText')}
          mode="partners"
        />
      </div>
    </PageLayout>
  );
}
